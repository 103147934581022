import React from "react";
import styles from "./BackBtn.module.scss";

const BackBtn = ({ goBack }) => {
  return (
    <button type="button" className={styles.btn} onClick={goBack}>
      Revenir à toutes les ressources
    </button>
  );
};

export default BackBtn;
