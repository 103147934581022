import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import gsap from "gsap";

import styles from "./Chart.module.scss";
import { parseCircleText, types } from "../../utils";
import useWindowDimensions from "../../useWindowSize";

const Chart = ({
  resources,
  selectedType,
  setSelectedType,
  selectedMuseum,
  openPopup,
}) => {
  const svgRef = useRef(null);
  const [filteredData, setFilteredData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [simulation, setSimulation] = useState();
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [isMobileView, setIsMobileWiew] = useState(false);
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const mobileBlockerOffset = 30;
  const onHoverSize = 56;
  const hoverRadiusMargin = 7;
  const blockerSize = 50;
  const collideStrength = 0.5;
  const chargeStrength = 0.1;
  const padding = 8;
  const growSpeed = 0.5;
  const containerColor = "#FFFFFF";
  const textColor = "#24232E";

  const mouseover = function (event, d) {
    if (d.id === "blocker") return;
    d.isHover = true;
    simulation.alphaTarget(0.03).restart();
    if (selectedType) {
      event.target.parentNode.querySelector(".hoverCircle").style.display =
        "block";
      gsap.to(d, {
        currentSize: Math.max(d.baseSize, onHoverSize),
        hoverSize: Math.max(d.baseSize, onHoverSize) + hoverRadiusMargin,
        backgroundColor: types.find((entry) => entry.id === selectedType)
          .color2,
        duration: growSpeed,
      });
      d.fx = d.x;
      d.fy = d.y;
    } else {
      gsap.to(d, {
        backgroundColor: d.color2,
        textColor: containerColor,
        iconColor: containerColor,
        duration: growSpeed,
      });
    }
  };
  const mouseleave = function (event, d) {
    if (d.id === "blocker") return;
    d.isHover = false;
    if (selectedType) {
      event.target.parentNode.querySelector(".hoverCircle").style.display =
        "none";
      gsap.to(d, {
        currentSize: d.baseSize,
        hoverSize: d.baseSize,
        backgroundColor: types.find((entry) => entry.id === selectedType)
          .color1,
        duration: growSpeed,
      });
      d.fx = null;
      d.fy = null;
    } else {
      gsap.to(d, {
        backgroundColor: d.color1,
        textColor: textColor,
        iconColor: d.color2,
        duration: growSpeed,
      });
    }
    simulation.alphaTarget(0.03);
  };
  const click = function (event, d) {
    if (d.id === "blocker") return;
    if (d.id) {
      setSelectedType(d.id);
    } else {
      openPopup(d);
    }
  };
  const updateSimulation = () => {
    if (!filteredData) return;
    simulation?.force("collide")?.initialize(filteredData);
    //simulation?.force("center")?.initialize(filteredData);
    //simulation?.force("charge")?.initialize(filteredData);
    //simulation.alpha(1).restart();
  };

  const simulationTick = () => {
    if (!width) return;
    updateSimulation();
    const group = d3
      .select(svgRef.current)
      .select("#dataContainer")
      .selectAll("g");
    if (!isMobileView) {
      group.attr("clamp", (d) => {
        const x = d.x - width / 2;
        const y = d.y - width / 2;
        const length = Math.sqrt(x * x + y * y);
        d.multiplier = Math.min(
          1,
          (width / 2 - d.hoverSize - padding) / length
        );
        d.x =
          d.id === "blocker"
            ? d.fx
            : (d.x - width / 2) * d.multiplier + width / 2;
        d.y =
          d.id === "blocker"
            ? d.fy
            : (d.y - width / 2) * d.multiplier + width / 2;
      });
    } else {
      group.attr("clamp", (d) => {
        if (d.id === "blocker") {
          d.x = d.fx;
          d.y = d.fy;
        } else {
          if (d.x < d.hoverSize + padding) {
            d.x = d.hoverSize + padding;
          } else if (d.x > width - d.hoverSize - padding) {
            d.x = width - d.hoverSize - padding;
          }
          if (d.y < d.hoverSize + padding) {
            d.y = d.hoverSize + padding;
          } else if (d.y > height - d.hoverSize - padding) {
            d.y = height - d.hoverSize - padding;
          }
        }
      });
    }
    group
      .style("transform", (d) => "translate(" + d.x + "px, " + d.y + "px)")
      .selectChild(".node")
      .attr("r", (d) => d.currentSize)
      .style("fill", (d) => d.backgroundColor);
    if (!selectedType) {
      group.selectAll("text").attr("fill", (d) => d.textColor);
      group.selectAll("svg").each((d, i, g) => {
        g[i]
          .querySelectorAll("path,line, circle, rect")
          .forEach((item) => item.setAttribute("stroke", d.iconColor));
      });
    }
    group.selectChild(".hoverCircle").attr("r", (d) => d.hoverSize);
    group
      .selectAll("." + styles.contentText)
      .style("display", (d) =>
        d.currentSize >= onHoverSize ? "block" : "none"
      )
      .attr("class", (d) =>
        d.currentSize >= onHoverSize
          ? styles.contentText + " " + styles.visible
          : styles.contentText + " " + styles.hidden
      );
  };

  const generateTypeCircleContent = (
    group,
    icon,
    iconColor,
    text,
    textColor
  ) => {
    d3.xml("imgs/" + icon).then((data) => {
      group.append(data.documentElement);
      const circle = d3.select(group);
      circle.selectAll("path,line").attr("stroke", iconColor);
      const iconWidth = circle.select("svg").attr("width");
      const iconHeight = circle.select("svg").attr("height");
      const circleRadius = circle.select("circle").attr("r");
      const parsedText = parseCircleText(text, circleRadius, iconHeight);
      circle
        .select("svg")
        .style("pointer-events", "none")
        .attr("x", -iconWidth / 2)
        .attr("y", parsedText[0].y - iconHeight - 16);
      parsedText.forEach((element) => {
        circle
          .append("text")
          .attr("text-anchor", "middle")
          .style("pointer-events", "none")
          .attr("x", 0)
          .attr("y", element.y)
          .attr("class", styles.circleLabel)
          .attr("fill", textColor)
          .text(element.text);
      });
    });
  };

  const generateCircleContent = (group, text) => {
    const circle = d3.select(group);
    const circleRadius =
      Math.max(circle.select("circle").attr("r"), onHoverSize) - 5;
    const parsedText = parseCircleText(text, circleRadius);
    parsedText.forEach((element) => {
      circle
        .append("text")
        .attr("text-anchor", "middle")
        .style("pointer-events", "none")
        .style("display", "none")
        .attr("x", 0)
        .attr("y", element.y)
        .attr("class", styles.contentText)
        .attr("fill", textColor)
        .text(element.text);
    });
  };

  // resize
  useEffect(() => {
    if (windowWidth >= 959) {
      setWidth(620);
      setIsMobileWiew(false);
    } else {
      setWidth(windowWidth);
      setIsMobileWiew(true);
    }
  }, [windowWidth]);

  //filtering
  useEffect(() => {
    if (!resources || !width) return;
    let data;
    if (selectedType) {
      data = resources.filter(function (d) {
        return d.type === selectedType;
      });
      data = data.filter(function (d) {
        return d.museums.some((item) => selectedMuseum.includes(item));
      });
    } else {
      data = JSON.parse(JSON.stringify(types));
      const availableTypes = [];
      resources.forEach((entry) => {
        if (
          !availableTypes.includes(entry.type) &&
          entry.museums.some((item) => selectedMuseum.includes(item))
        ) {
          availableTypes.push(entry.type);
        }
      });
      data = data.filter((entry) => availableTypes.includes(entry.id));
    }

    let minSize = 10000;
    let maxSize = -1;
    data.forEach((item) => {
      const nb = parseInt(item.nbEpisode) || 1;
      if (nb < minSize) minSize = nb;
      if (nb > maxSize) maxSize = nb;
    });

    let scale;
    if (minSize < maxSize) {
      scale = d3.scaleSqrt().domain([minSize, maxSize]).range([1, 2]);
    } else {
      scale = () => 1;
    }
    let scaleFactor;
    let calculatedHeight = 0;
    if (isMobileView) {
      scaleFactor = 60;
      let surface = 0;
      data.forEach((item) => {
        const value = scale(item.nbEpisode || 1) * scaleFactor;
        surface += (value * 2) ** 2;
      });
      calculatedHeight = Math.max(windowHeight * 0.5, (surface * 1.8) / width);
      setHeight(calculatedHeight);
    } else {
      const totalSurface = (3.14 * (width / 2) ** 2) / 2;
      let circleSurface = 0;
      data.forEach((item) => {
        const value = scale(item.nbEpisode || 1);
        circleSurface += 3.14 * value ** 2;
      });
      scaleFactor =
        (Math.sqrt(totalSurface) / Math.sqrt(circleSurface)) *
        (0.9 - (0.1 * data.length) / 300);
    }

    data.unshift({
      id: "blocker",
      name: "",
      fx: !isMobileView
        ? Math.sin(135 * (Math.PI / 180)) * (width / 2) + width / 2
        : width - blockerSize - mobileBlockerOffset,
      fy: !isMobileView
        ? Math.cos(135 * (Math.PI / 180)) * (width / 2) + width / 2
        : 0,
    });

    data.forEach((item, index) => {
      item.baseSize = scale(item.nbEpisode || 1) * scaleFactor;
      item.currentSize = item.id === "blocker" ? blockerSize : item.baseSize;
      item.isHover = false;
      item.textColor = textColor;
      item.iconColor = item.color2 || "#FF9900";
      item.hoverSize = item.currentSize;
      if (selectedType) {
        item.borderColor =
          item.id === "blocker"
            ? "#FFFFFF"
            : types.find((entry) => entry.id === selectedType).color2 ||
              "#FF9900";
      }
      item.backgroundColor =
        item.id === "blocker"
          ? containerColor
          : item.color1 ||
            types.find((entry) => entry.id === selectedType).color1;
      if (!isMobileView) {
        let a = Math.random() * 2 * Math.PI; // angle
        let r = Math.sqrt(~~(Math.random() * (width / 2) ** 2));
        item.x = r * Math.cos(a) + width / 2;
        item.y = r * Math.sin(a) + width / 2;
      } else {
        item.x =
          item.currentSize + Math.random() * (width - item.currentSize * 2);
        item.y = (index * calculatedHeight) / data.length;
      }
    });

    setFilteredData(data);
  }, [resources, selectedType, selectedMuseum, width]);

  //onFilteringUpdate
  useEffect(() => {
    if (!filteredData || !isLoaded) return;
    simulation?.stop();
    gsap.killTweensOf();
    const svgEl = d3.select(svgRef.current);
    svgEl.select("#dataContainer").selectAll("*").remove();

    svgEl
      .select("#dataContainer")
      .selectAll("g")
      .data(filteredData)
      .join((enter, index) => {
        const group = enter.append("g");
        group.attr("id", (d) => d.id || null);
        //item hover
        if (selectedType) {
          group
            .append("circle")
            .attr("class", "hoverCircle")
            .attr("r", (d) => d.currentSize + 10)
            .attr("cx", 0)
            .attr("cy", 0)
            .style("pointer-events", "none")
            .style(
              "stroke",
              types.find((entry) => entry.id === selectedType).color2
            )
            .style("stroke-width", 1.5)
            .style(
              "fill",
              types.find((entry) => entry.id === selectedType).color1
            )
            .style("fill-opacity", 0)
            .style("display", "none")
            .style(
              "filter",
              "drop-shadow(0px 2px 35px rgba(138, 158, 133, 0.7))"
            );
        } else {
          //itemIcon
          group.each((item, index, groups) => {
            if (item.icon) {
              generateTypeCircleContent(
                groups[index],
                item.icon,
                item.color2,
                item.nom,
                textColor
              );
            }
          });
        }
        //base circle
        group
          .append("circle")
          .attr("class", "node")
          .attr("r", (d) => d.currentSize)
          .attr("cx", 0)
          .attr("cy", 0)
          .style("fill", (d) => d.backgroundColor)
          .style("fill-opacity", 1)
          .style("stroke", (d) => d.borderColor)
          .style("stroke-width", selectedType ? 1.5 : 0)
          .on("mouseover", mouseover)
          .on("click", click)
          .on("mouseleave", mouseleave);

        //text
        if (selectedType) {
          group.each((item, index, groups) => {
            if (item.id === "blocker") return;
            generateCircleContent(groups[index], item.nom);
          });
        }
        //blocker
        svgEl.select("#dataContainer #blocker .hoverCircle").remove();
        if (selectedType) {
          generateTypeCircleContent(
            svgEl.select("#dataContainer #blocker").node(),
            types.find((item) => item.id === selectedType).icon,
            types.find((entry) => entry.id === selectedType).color2,
            types.find((item) => item.id === selectedType).nom,
            textColor
          );
        } else {
          const blocker = svgEl.select("#dataContainer #blocker");
          blocker
            .append("text")
            .attr("text-anchor", "middle")
            .attr("class", styles.museumCount)
            .attr("dy", 10)
            .text(selectedMuseum.length);
          blocker
            .append("text")
            .attr("text-anchor", "middle")
            .attr("class", styles.museumCountLabel)
            .attr("dy", 28)
            .text("musée" + (selectedMuseum.length > 1 ? "s" : ""));
        }
      })
      .style("transform", (d) => "translate(" + d.x + "px, " + d.y + "px)");
    updateSimulation();
    simulation.alphaTarget(0.03).restart();
    simulation.nodes(filteredData).on("tick", simulationTick);
  }, [filteredData, isLoaded]);

  //initialize
  useEffect(() => {
    if (!filteredData || isLoaded) return;
    setIsLoaded(true);

    const simulationsForces = d3
      .forceSimulation()
      .force("charge", d3.forceManyBody().strength(chargeStrength))
      .force(
        "collide",
        d3
          .forceCollide()
          .strength(collideStrength)
          .radius(function (d) {
            return d.hoverSize + padding;
          })
          .iterations(1)
      );
    setSimulation(simulationsForces);
  }, [filteredData]);

  return (
    <div className={styles.chartWrapper}>
      {filteredData?.length === 1 && (
        <div className={styles.emptyMessage} style={{ color: textColor }}>
          Aucune ressource ne correspond à vos critères, veuillez modifier votre
          sélection.
        </div>
      )}
      <svg
        ref={svgRef}
        width={width}
        height={isMobileView ? height + blockerSize : width}
      >
        <g
          id="border"
          transform={"translate(0," + (isMobileView ? blockerSize : 0) + ")"}
        >
          {!isMobileView ? (
            <circle
              cx={width / 2}
              cy={width / 2}
              r={width / 2}
              fill={containerColor}
              pointerEvents="none"
              mask="url(#mask)"
            ></circle>
          ) : (
            <rect
              x="0"
              y="0"
              fill={containerColor}
              mask="url(#mask)"
              pointerEvents="none"
              width={width}
              height={height}
            />
          )}
          <mask id="mask">
            {!isMobileView ? (
              <circle
                cx={width / 2}
                cy={width / 2}
                r={width / 2}
                fill="#FFFFFF"
                pointerEvents="none"
              ></circle>
            ) : (
              <rect
                x="0"
                y="0"
                width={width}
                height={height}
                fill="#FFFFFF"
                pointerEvents="none"
              />
            )}
            <circle
              cx={
                isMobileView
                  ? width - blockerSize - mobileBlockerOffset
                  : Math.sin(135 * (Math.PI / 180)) * (width / 2) + width / 2
              }
              cy={
                isMobileView
                  ? 0
                  : Math.cos(135 * (Math.PI / 180)) * (width / 2) + width / 2
              }
              r={blockerSize + padding}
              fill="#000000"
              pointerEvents="none"
            ></circle>
          </mask>
        </g>

        <g
          id="dataContainer"
          transform={"translate(0," + (isMobileView ? blockerSize : 0) + ")"}
          className={styles.wrapper}
        ></g>
      </svg>
    </div>
  );
};

export default Chart;
