import React from "react";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <header role="banner" className={styles.header}>
      <div className={styles.wrapper}>
        <a
          href="https://www.parismusees.paris.fr/fr"
          target="_blank"
          rel="noreferrer"
        >
          <img src="imgs/logo.svg" alt="" />
        </a>
        <a
          href="https://www.parismusees.paris.fr/fr"
          target="_blank"
          rel="noreferrer"
          className={styles.backTowebsite}
        >
          Revenir sur site parismusees.paris.fr
        </a>
      </div>
    </header>
  );
};

export default Header;
