export const museumData = [
  { id: "catacombes", name: "Catacombes de Paris" },
  {
    id: "crypte",
    name: "Crypte archéologique de l'Ile de la Cité",
  },
  { id: "balzac", name: "Maison de Balzac" },
  {
    id: "mvh",
    name: "Maison de Victor Hugo - Hauteville House",
  },
  { id: "bourdelle", name: "Musée Bourdelle" },
  {
    id: "carnavalet",
    name: "Musée Carnavalet, Histoire de Paris",
  },
  {
    id: "cernuschi",
    name: "Musée Cernuschi, musée des Arts de l’Asie de la Ville de Paris",
  },
  {
    id: "cognacqJay",
    name: "Musée Cognacq-Jay, le goût du XVIIIe",
  },
  { id: "artModerne", name: "Musée d’Art Moderne de Paris" },
  {
    id: "liberation",
    name: "Musée de la Libération de Paris - musée du Général Leclerc - musée Jean Moulin",
  },
  { id: "vieRomantique", name: "Musée de la Vie romantique" },
  { id: "zadkine", name: "Musée Zadkine" },
  {
    id: "galliera",
    name: "Palais Galliera, musée de la Mode de la Ville de Paris",
  },
  {
    id: "petitPalais",
    name: "Petit Palais, musée des Beaux-arts de la Ville de Paris",
  },
];
export const types = [
  {
    id: "coursEnLigne",
    nom: "Cours en ligne",
    color1: "#E0F8DA",
    color2: "#8B9E86",
    icon: "icoCours.svg",
  },
  {
    id: "videos",
    nom: "Vidéos",
    color1: "#ECF7E3",
    color2: "#A0D8A4",
    icon: "icoVideos.svg",
  },
  {
    id: "ressourcesPedagogiques",
    nom: "Ressources pédagogiques",
    color1: "#FFF2D6",
    color2: "#E7A53E",
    icon: "icoRessourcesPedagogiques.svg",
  },
  {
    id: "parcoursExpositions",
    nom: "Parcours et expositions en ligne",
    color1: "#E1ECF5",
    color2: "#75889A",
    icon: "icoParcoursExposition.svg",
  },
  {
    id: "visitesVirtuelles",
    nom: "Visites virtuelles",
    color1: "#FFDBCE",
    color2: "#F86A34",
    icon: "icoVisites.svg",
  },
  {
    id: "agentsConversationnels",
    nom: "Chatbots",
    color1: "#E1EBFF",
    color2: "#3463BE",
    icon: "icoAgents.svg",
  },
  {
    id: "oeuvresALaLoupe",
    nom: "Oeuvres à la loupe",
    color1: "#F7E1FF",
    color2: "#D881F5",
    icon: "icoLoupe.svg",
  },
  {
    id: "podcasts",
    nom: "Podcasts",
    color1: "#E4F9F5",
    color2: "#58B19F",
    icon: "icoPodcasts.svg",
  },
  {
    id: "jeux",
    nom: "Jeux",
    color1: "#FFF3EC",
    color2: "#A78F82",
    icon: "icoJeux.svg",
  },
  {
    id: "sitesThematiques",
    nom: "Sites thématiques",
    color1: "#F5F4FF",
    color2: "#7E60AD",
    icon: "icoSitesThematiques.svg",
  },
  {
    id: "cataloguesEnLigne",
    nom: "Catalogues en ligne",
    color1: "#FFD8D8",
    color2: "#FA6B6B",
    icon: "icoCatalogue.svg",
  },
  {
    id: "experiencesAuMusee",
    nom: "Experiences au musée",
    color1: "#FFE9E0",
    color2: "#FFAF75",
    icon: "icoExperienceMusees.svg",
  },
  {
    id: "applicationsMobiles",
    nom: "Applications mobiles",
    color1: "#FFE6F2",
    color2: "#B33771",
    icon: "icoAppli.svg",
  },
  {
    id: "collectionsEnLigne",
    nom: "Collections en ligne",
    color1: "#EFF9FD",
    color2: "#6FA0E7",
    icon: "icoCollection.svg",
  },
  {
    id: "plans",
    nom: "Plans",
    color1: "#D9F6FA",
    color2: "#5D9299",
    icon: "icoPlans.svg",
  },
  {
    id: "sitesDesMusees",
    nom: "Sites des musées",
    color1: "#E4E8FF",
    color2: "#6666E1",
    icon: "icoSitesMusees.svg",
  },
];
const getTextWidth = (text, font) => {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

const getCircleWidth = (y, radius) => {
  const yPos = Math.abs(y / radius);
  const alpha = Math.asin(yPos);
  const xPos = Math.cos(alpha);
  return Math.abs(xPos * radius * 2) - 10;
};

const concatText = (words, splitPos) => {
  let filteredWords = [...words];
  if (splitPos < filteredWords.length) {
    filteredWords = filteredWords.slice(0, splitPos);
  }
  return filteredWords.join(" ");
};

const tryFitPos = (linesPos, text, radius, force) => {
  const res = [];
  let words = text.split(" ");
  let currentLine = 0;
  let splitPos;
  while (currentLine < linesPos.length) {
    splitPos = words.length;
    while (
      splitPos > 1 &&
      getTextWidth(
        concatText(words, splitPos),
        "bold 14px RobotoCondensed-Bold"
      ) > getCircleWidth(linesPos[currentLine], radius)
    ) {
      splitPos--;
    }

    res.push({
      text: words.slice(0, splitPos).join(" "),
      y: linesPos[currentLine],
    });
    words = words.slice(splitPos);
    currentLine++;
  }
  if (words.length > 0) {
    if (force) {
      res.push({ text: "...", y: linesPos[linesPos.length - 1] + 8 });
      return res;
    } else {
      return null;
    }
  } else {
    return res;
  }
};

export const parseCircleText = (text, radius, iconHeight = null) => {
  const lineHeight = 16;
  let currentPosIndex = 0;
  const offset = lineHeight / 2;
  let linesPos = iconHeight
    ? [
        [lineHeight / 2 + iconHeight / 3],
        [iconHeight / 3, lineHeight + iconHeight / 3],
        [
          -lineHeight / 2 + iconHeight / 3,
          lineHeight / 2 + iconHeight / 3,
          lineHeight * 1.5 + iconHeight / 3,
        ],
      ]
    : [
        [0 + offset],
        [-lineHeight * 0.5 + offset, lineHeight * 0.5 + offset],
        [-lineHeight * 1 + offset, 0 + offset, lineHeight * 1 + offset],
        [
          -lineHeight * 1.5 + offset,
          -lineHeight * 0.5 + offset,
          lineHeight * 0.5 + offset,
          lineHeight * 1.5 + offset,
        ],
        [
          -lineHeight * 2 + offset,
          -lineHeight * 1 + offset,
          0 + offset,
          lineHeight * 1 + offset,
          lineHeight * 2 + offset,
        ],
      ];
  let res = null;
  while (!res && currentPosIndex < linesPos.length) {
    res = tryFitPos(linesPos[currentPosIndex], text, radius);
    currentPosIndex++;
  }
  if (!res) {
    res = tryFitPos(linesPos[linesPos.length - 1], text, radius, true);
  }
  return res;
};
