import React, { useLayoutEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import styles from "./Popup.module.scss";
import { museumData, types } from "../../utils";
import Header from "../Header";

const CloseBtn = ({ setMuseumListOpen, onClose }) => {
  return (
    <button
      type="button"
      onClick={() => {
        setMuseumListOpen(false);
        onClose();
      }}
      className={styles.closeBtn}
    >
      <img src="imgs/closePopup.svg" alt="fermer" />
    </button>
  );
};

const Popup = ({ item, open, isFullscreen, onClose }) => {
  const [museumListOpen, setMuseumListOpen] = useState(false);
  const popupRef = useRef();
  const titleAreaRef = useRef();
  const titleNbMuseums = useRef();
  const infosContainer = useRef();

  useLayoutEffect(() => {
    if (!open) return;
    setDescriptionHeight(
      391 -
        titleAreaRef?.current?.clientHeight -
        infosContainer?.current?.clientHeight -
        40 +
        "px"
    );

    setListMuseumsHeight(
      391 -
        titleAreaRef?.current?.clientHeight -
        titleNbMuseums?.current?.clientHeight -
        68 +
        "px"
    );
  }, [open]);

  const [descriptionHeight, setDescriptionHeight] = useState();
  const [listMuseumsHeight, setListMuseumsHeight] = useState();

  if (!item) return null;
  const type = types.find((entry) => entry.id === item.type);
  const showMuseums = () => {
    setMuseumListOpen((value) => !value);
  };

  return (
    <Dialog open={open} fullScreen={isFullscreen} disablePortal>
      {isFullscreen && <Header />}
      <div className={styles.wrapper} ref={popupRef}>
        {isFullscreen && (
          <CloseBtn setMuseumListOpen={setMuseumListOpen} onClose={onClose} />
        )}
        <div className={styles.leftContainer}>
          <div
            className={styles.typeWrapper}
            style={{ backgroundColor: type.color1 }}
          >
            <img src={"imgs/" + type.icon} className={styles.icon} alt="" />
            <p className={styles.type}>{type.nom}</p>
          </div>
          <div className={styles.img}>
            {item.image !== "" ? (
              <img src={item.image} alt="" />
            ) : (
              <a
                href="https://www.parismusees.paris.fr/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="imgs/logo.svg" alt="" className={styles.defaultImg} />
              </a>
            )}
          </div>

          {item.lien !== "" && (
            <a
              href={item.lien}
              className={styles.goToContent}
              target="_blank"
              rel="noreferrer"
            >
              Accéder au contenu
            </a>
          )}
        </div>
        <div
          className={styles.rightContainer}
          {...(museumListOpen && { style: { justifyContent: "unset" } })}
        >
          <div>
            <div className={styles.titleContainer} ref={titleAreaRef}>
              <p className={styles.title}>{item.nom || ""}</p>
              {!isFullscreen && (
                <CloseBtn
                  setMuseumListOpen={setMuseumListOpen}
                  onClose={onClose}
                />
              )}
            </div>
            {item.description && (!museumListOpen || isFullscreen) && (
              <p
                className={styles.description}
                {...(!isFullscreen && {
                  style: {
                    maxHeight: descriptionHeight,
                  },
                })}
              >
                {item.description}
              </p>
            )}
          </div>
          <div className={styles.infosContainer} ref={infosContainer}>
            <div
              className={
                museumListOpen
                  ? styles.museumContainerOpen
                  : styles.museumContainer
              }
            >
              {item.museums.length === 1 ? (
                <div className={styles.museums}>
                  <img
                    src="imgs/museumIcon.svg"
                    className={styles.icon}
                    alt="musée"
                  />
                  <span>
                    {
                      museumData.find((entry) => entry.id === item.museums[0])
                        .name
                    }
                  </span>
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    className={styles.museums}
                    onClick={showMuseums}
                    ref={titleNbMuseums}
                  >
                    <img
                      src="imgs/museumIcon.svg"
                      className={styles.icon}
                      alt="musée"
                    />
                    <span>{item.museums.length + " musées"}</span>
                    <img
                      className={
                        museumListOpen
                          ? styles.dropdownIconRotated
                          : styles.dropdownIcon
                      }
                      src="imgs/dropdown.svg"
                      alt=""
                    />
                  </button>
                </>
              )}
              <ul
                {...(!isFullscreen && {
                  style: {
                    maxHeight: listMuseumsHeight,
                  },
                })}
                className={styles.museumsList}
              >
                {museumListOpen &&
                  item.museums.map((entry) => (
                    <li>
                      {museumData.find((museum) => museum.id === entry).name}
                    </li>
                  ))}
              </ul>
            </div>
            {!museumListOpen && item.langue !== "" && (
              <div className={styles.langue}>
                <img
                  className={styles.icon}
                  src="imgs/langIcon.svg"
                  alt="langue"
                />
                <span>{item.langue}</span>
              </div>
            )}
            {!museumListOpen && item.tarif !== "" && (
              <div className={styles.tarif}>
                <img
                  className={styles.icon}
                  src="imgs/priceIcon.svg"
                  alt="tarif"
                />
                <span>{item.tarif}</span>
              </div>
            )}
            {!museumListOpen && item.duree !== "" && (
              <div className={styles.duree}>
                <img
                  className={styles.icon}
                  src="imgs/durationIcon.svg"
                  alt="durée"
                />
                <span>{item.duree}</span>
              </div>
            )}
            {!museumListOpen && item.lieu !== "" && (
              <div className={styles.lieu}>
                <img
                  className={styles.icon}
                  src="imgs/locationIcon.svg"
                  alt="lieu"
                />
                <span>{item.lieu}</span>
              </div>
            )}
            {!museumListOpen && item.mode !== "" && (
              <div className={styles.mode}>
                <img
                  className={styles.icon}
                  src="imgs/modeIcon.svg"
                  alt="mode"
                />
                <span>{item.mode}</span>
              </div>
            )}
            {!museumListOpen && item.playlist !== "" && (
              <div className={styles.playlist}>
                <img
                  className={styles.icon}
                  src="imgs/playlistIcon.svg"
                  alt="playlist"
                />
                <span>
                  {item?.nbEpisode !== ""
                    ? item.nbEpisode + " épisodes"
                    : "playlist"}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Popup;
