import { useEffect, useState } from "react";
import styles from "./App.module.scss";
import "./styles/globals.scss";
import Chart from "./components/Chart";
import * as d3 from "d3";
import BackBtn from "./components/BackBtn";
import MuseumSelector from "./components/MuseumSelector";
import { museumData } from "./utils";
import Popup from "./components/Popup";
import useWindowDimensions from "./useWindowSize";
import Header from "./components/Header";

function App() {
  const initialState = museumData.map((m) => m.id);

  const [resources, setResources] = useState();
  const [filters, setFilters] = useState(initialState);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const loadData = async () => {
    const csvData = await d3.csv(
      "https://pm-paris.dev.ows.fr/cartographie/data.csv"
      //"data.csv"
    );
    const resources = [];
    csvData.forEach((element) => {
      const museums = [];
      museumData.forEach((museum) => {
        if (element[museum.id] === "x") {
          museums.push(museum.id);
        }
        delete element[museum.id];
      });
      const resource = { museums, ...element };
      resources.push(resource);
    });
    setResources(resources);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!filters || filters.length === 0) setFilters(initialState);
  }, [filters]);

  const openPopup = (item) => {
    setSelectedItem(item);
    setPopupOpen(true);
  };

  const { width: windowWidth } = useWindowDimensions();
  const [isActive, setActive] = useState(true);

  const closeInfo = () => {
    setActive(false);
  };

  return (
    <>
      <Popup
        item={selectedItem}
        open={popupOpen}
        isFullscreen={windowWidth < 575}
        onClose={() => {
          setPopupOpen(false);
          setSelectedItem(null);
        }}
      ></Popup>
      {windowWidth < 959 && (
        <div
          className={
            isActive === true
              ? `${styles.show} ${styles.info}`
              : `${styles.hide} ${styles.info}`
          }
        >
          <div className={styles.logos}>
            <img src="imgs/logoWhite.svg" alt="" />
          </div>
          <p>
            Pour une expérience optimisée, nous vous recommandons de visiter ce
            site sur un ordinateur.
          </p>

          <button onClick={closeInfo}>Accéder à la cartographie</button>
        </div>
      )}
      <Header />
      <main className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.filtersContainer}>
            <h1>
              <span>Cartographie </span>
              des ressources et expériences numériques
            </h1>

            <MuseumSelector
              filters={filters}
              setFilters={setFilters}
              museumData={museumData}
              initialState={initialState}
            />
          </div>
          <div className={styles.containerChart}>
            {selectedType && <BackBtn goBack={() => setSelectedType(null)} />}
            <Chart
              resources={resources}
              selectedMuseum={filters}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              openPopup={(item) => openPopup(item)}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default App;
