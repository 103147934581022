import React, { useState } from "react";
import styles from "./MuseumSelector.module.scss";
import useWindowDimensions from "./../../useWindowSize";

const MuseumSelector = ({ museumData, filters, setFilters, initialState }) => {
  const isActive = (museum) => {
    if (initialState.every((element) => filters.includes(element)))
      return false;
    return filters.includes(museum);
  };

  const handleChange = (e) => {
    if (e.target.name === "all") {
      if (initialState.every((element) => filters.includes(element))) {
        return;
      } else {
        setFilters(initialState);
      }
    } else if (initialState.every((element) => filters.includes(element))) {
      setFilters([e.target.name]);
    } else {
      !isActive(e.target.name)
        ? setFilters([...filters, e.target.name])
        : setFilters(filters.filter((item) => item !== e.target.name));
    }
  };

  const { width: windowWidth } = useWindowDimensions();

  // const ref = useOuterClick((event) => {
  //   if (event.target !== document.querySelector("#filter-open-btn") && opened) {
  //     setOpened(false);
  //   }
  // });
  const [opened, setOpened] = useState(false);
  const manageOpenList = () => {
    // if (ref.current.style.visibility === "hidden") {
    //   ref.current.style.visibility = "visible";
    // }
    setOpened((open) => !open);
  };

  return (
    <div>
      {windowWidth <= 959 && (
        <button
          type="button"
          aria-controls="filter-list"
          id="filter-open-btn"
          onClick={manageOpenList}
          aria-expanded={opened}
          className={`${styles.btnFilters} ${opened && styles.btnClose}`}
        >
          Filtrer par Musées
        </button>
      )}
      <div
        className={`${styles.filtersWrapper} ${
          opened ? ` ${styles.opened}` : ""
        }`}
      >
        <div className={styles.filtersBlock}>
          <label
            className={
              initialState.every((element) => filters.includes(element))
                ? styles.selected
                : styles.unselected
            }
            id={styles.selectAll}
          >
            <input
              type="checkbox"
              checked={initialState.every((element) =>
                filters.includes(element)
              )}
              onChange={handleChange}
              name={"all"}
            />
            Tous les musées
          </label>
          <ul>
            {museumData.map((museum) => {
              const isChecked = isActive(museum.id);
              return (
                <li key={museum.id}>
                  <label
                    className={isChecked ? styles.selected : styles.unselected}
                  >
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name={museum.id}
                    />
                    {museum.name}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MuseumSelector;
